
export interface IIndividual {
  id: IndividualId;
  firstName?:string;
  lastName?:string;
  streetAddress?:string;
  zipCode?:string;
  phone?:string;
  createdDate?:string;
  referredBy?:string;
  rewardsBalance?:number;
  launchLeaderStatus?:string;
  launchLeaderApplicationOrigin?:string;
  rewards?:any[];
  provider: boolean;
}

export interface IndividualId {
  id:string;
  email:string;
}

export function createEmptyIndividual():IIndividual {
  return {
    id: {
      id: '',
      email: '',
    },
    firstName: '',
    lastName: '',
    streetAddress: '',
    zipCode: '',
    phone: '',
    createdDate: '',
    rewardsBalance: 0,
    provider: false,
  };
}

export interface IIndividualReward {
  amount: number;
  status: string|null;
}

export class LaunchLeaderStatusTypes {
  static get APPLICATION_RECEIVED():string {
    return 'APPLICATION_RECEIVED';
  }
  static get IN_PROGRESS():string {
    return 'IN_PROGRESS';
  }
  static get DONE():string {
    return 'DONE';
  }
  static get CANCELLED():string {
    return 'CANCELLED';
  }

  static list = [
    { id: LaunchLeaderStatusTypes.APPLICATION_RECEIVED, name: 'APPLICATION_RECEIVED' },
    { id: LaunchLeaderStatusTypes.IN_PROGRESS, name: 'IN_PROGRESS' },
    { id: LaunchLeaderStatusTypes.DONE, name: 'DONE' },
    { id: LaunchLeaderStatusTypes.CANCELLED, name: 'CANCELLED' },
  ];
}

export class IndividualRewardTypes {
  static get LAUNCH_LEADER_REFERRAL():string {
    return 'LAUNCH_LEADER_REFERRAL';
  }
  static get LAUNCH_LEADER_APPLICATION():string {
    return 'LAUNCH_LEADER_APPLICATION';
  }
  static get NEIGHBOR_REFERRAL():string {
    return 'NEIGHBOR_REFERRAL';
  }
}

export interface IIndividualRewardAuditHistory {
  creatorId: string;
  createDate: string;
  context?: string;
  amount: number;
  rewardType?: string;
  actionType: string;
  endingBalance?: number;
}