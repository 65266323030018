
import { AutocompleteChangeReason, AutocompleteInputChangeReason, FormControl, FormHelperText } from '@mui/material';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';

import { IDropdownOption } from 'model/dropdown';
import { useEffect } from 'react';
import HookAsyncAutocomplete from 'components/reactHookForm/HookAsyncAutocomplete';
import useNeighborhoodMultiAutocomplete from './useNeighborhoodMultiAutocomplete';
import { INeighborhood } from 'model/neighborhood';


export interface INeighborhoodMultiAutocompleteProps {
  label:string;
  fieldName?: string;
  initialOptions?: IDropdownOption[];
  formless: boolean;
  placeholder:string;
  suppressEmptyStringSearch?:boolean;
  postOnChange?: (formCtx: any, selectedOptions: string[], reason: AutocompleteChangeReason, details: any, rawValues: IDropdownOption[]) => void;
  postOnInputChange?: (formCtx: any, nextVal: string | null, reason: AutocompleteInputChangeReason, details: any) => void;
  controlledValues?: string[];
  statuses?: string[];
  extraProps?:any;
  required: boolean;
  optionDescriptionFunc?: (data:INeighborhood) => string;
}

interface IFormlessForm {
  selectedNeighborhoods:string[];
}

export default function NeighborhoodMultiAutocomplete({
  label,
  fieldName,
  initialOptions,
  formless,
  placeholder,
  suppressEmptyStringSearch,
  statuses,
  controlledValues,
  postOnChange,
  postOnInputChange,
  extraProps,
  required,
  optionDescriptionFunc,
}:INeighborhoodMultiAutocompleteProps) {
  if (!formless && !fieldName) {
    throw new Error('fieldName is required when formless is false');
  }
  const { options, onSearchNeighborhoods, initWithValue, initDropdownOptions } = useNeighborhoodMultiAutocomplete();

  let rules: any;
  if (required) {
    rules = {
      required: {
        value: true, message: 'Required',
      },
    };
  }

  if (!formless) {
    const formCtx = useFormContext();
    const { isError, getErrorMessage } = useErrorState(formCtx);

    useEffect(()=> {
      if (initialOptions && initialOptions.length > 0 && options.length === 0) {
        void initDropdownOptions(formCtx, initialOptions);
      }
    }, [initialOptions]);

    useEffect(() => {
      if (controlledValues && options.length === 0) {
        void initWithValue(formCtx, fieldName!, controlledValues, statuses ?? [], optionDescriptionFunc);
      }
    }, [controlledValues]);


    return (
      <FormControl variant='standard' fullWidth error={formless ? false : isError(fieldName!)}>
        <HookAsyncAutocomplete
          rules={rules}
          name={fieldName!}
          label={label}
          dropdownOptions={options}
          placeholder={placeholder}
          onInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
            if (!suppressEmptyStringSearch || value) {
              var currentSelected = formCtx.getValues(fieldName!);
              if (reason !== 'reset') {
                void onSearchNeighborhoods(value, statuses ?? [], currentSelected, optionDescriptionFunc);
              }
              if (postOnInputChange) {
                postOnInputChange(formCtx, value, reason, null);
              }
            }
          }}
          postOnChange={(formContext, values, reason, details, rawValues) => {
            if (postOnChange) {
              postOnChange(formContext, values, reason, details, rawValues);
            }

          }}
        />
        <FormHelperText>{getErrorMessage(fieldName!) || ' '}</FormHelperText>
      </FormControl>
    );
  } else {
    const formContext = useForm<IFormlessForm>({
      mode: 'onTouched',
      defaultValues: {
        selectedNeighborhoods: [],
      },
    });
    const { isError, getErrorMessage } = useErrorState(formContext);


    useEffect(()=> {
      if (initialOptions && initialOptions.length > 0 && options.length === 0) {
        void initDropdownOptions(formContext, initialOptions);
      }
    }, [initialOptions]);

    useEffect(() => {
      if (controlledValues && options.length === 0) {
        void initWithValue(formContext, 'selectedNeighborhoods', controlledValues, statuses ?? [], optionDescriptionFunc);
      }
    }, [controlledValues]);

    return (
      <FormProvider {...formContext}>
        <FormControl variant='standard' fullWidth error={formless ? false : isError('selectedNeighborhoods')}>
          <HookAsyncAutocomplete
            rules={rules}
            name={'selectedNeighborhoods'}
            label={label}
            dropdownOptions={options}
            placeholder={placeholder}
            onInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
              if (!suppressEmptyStringSearch || value) {
                var currentSelected = formContext.getValues('selectedNeighborhoods');
                if (reason !== 'reset') {
                  void onSearchNeighborhoods(value, statuses ?? [], currentSelected, optionDescriptionFunc);
                }
                if (postOnInputChange) {
                  postOnInputChange(formContext, value, reason, null);
                }
              }
            }}
            postOnChange={(formCtx, values, reason, details, rawValues) => {
              if (postOnChange) {
                postOnChange(formCtx, values, reason, details, rawValues);
              }
            }}
            extraProps={extraProps}
          />
          <FormHelperText>{getErrorMessage('selectedNeighborhoods') || ' '}</FormHelperText>
        </FormControl>
      </FormProvider>
    );
  }

}

