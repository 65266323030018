import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import MarketNameCell from './components/MarketNameCell';

export const columns: GridColDef[] = [
  {
    field: 'marketId',
    headerName: 'Market',
    width: 200,
    renderCell: (params: any) => {
      return (<MarketNameCell marketId={params.row.marketId}/>);
    },
  },
  {
    field: 'sendDate',
    headerName: 'Send Date',
    width: 300,
    renderCell: (params: any) => {
      return <div>{params.row.sendDate}</div>;
    },
  },
  {
    field: 'batchName',
    headerName: 'Batch Name',
    width: 300,
    renderCell: (params: any) => {
      return <div>{params.row.batchName}</div>;
    },
  },
  {
    field: 'batchStatus',
    headerName: 'Batch Status',
    width: 300,
    renderCell: (params: any) => {
      return <div>{params.row.batchStatus}</div>;
    },
  },
];