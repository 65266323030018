import { createValidateIntegerRange, createValidateNumber } from 'util/rhfValidateFunctions';
import { FormControl, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext, useWatch } from 'react-hook-form';
import { HookTextField, HookCheckbox, HookSelect, HookIntegerField, HookPriceField } from 'components/reactHookForm';
import HookPercentField from 'components/reactHookForm/HookPercentField';
import StatusTypeSelect from '../StatusTypeSelect';
import { useState } from 'react';
import { StreetFairProviderRatings } from 'model/serviceProvider';
import MarketSelect from './MarketSelect';
import ServiceProviderCrmCreatableAutocomplete from 'components/serviceProviderCrmCreatableAutocomplete/ServiceProviderCrmCreatableAutocomplete';

const validateNumber = createValidateNumber('Can only have numbers');
const validateQualitativeScore = createValidateIntegerRange(1, 10);

export default function MainInfo() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const [streetFairRatingsMenuItems] = useState<any[]>(StreetFairProviderRatings.list);
  const recurringPercent = useWatch({ control: formCtx.control, name: 'recurringPercent' });
  const oneTimePercent = useWatch({ control: formCtx.control, name: 'oneTimePercent' });
  const customConditions = useWatch({ control: formCtx.control, name: 'customConditions' });
  return (
    <Grid container item spacing={1} xs={12}>
      <Grid item xs={3} md={2}>
        <HookTextField
          label='Provider name'
          name='name'
          required
        />
      </Grid>
      <Grid item xs={1}>
        <MarketSelect />
      </Grid>
      <Grid item xs={2}>
        <HookTextField
          label='Website URL'
          name='websiteUrl'
        />
      </Grid>
      <Grid item xs={1}>
        <StatusTypeSelect/>
      </Grid>

      <Grid item xs={2}>
        <HookIntegerField
          label='Max Service Instances Shown'
          name='maxServiceInstancesShown'
        />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={2}>
        <HookCheckbox
          label='Are SMS notifications enabled?'
          name='smsNotificationEnabled'
          defaultValue={false}
        />
      </Grid>
      <Grid item xs={2}>
        <HookIntegerField
          name='serviceReminderDaysPrior'
          label='Service Reminder Days Prior'
        />
      </Grid>
      <Grid item xs={2}>
        <HookCheckbox
          label='Neighborhood Notifications Enabled?'
          name='neighborhoodNotificationsAllowed'
          defaultValue={false}
        />
      </Grid>
      <Grid item xs={2}>
        <HookCheckbox
          label='In-App Chat Excluded?'
          name='inAppChatExcluded'
          defaultValue={false}
        />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid container item xs={12} spacing={1} sx={{ border: '1px solid #EAEAEA', margin: '4px' }}>
        <Grid item xs={12}>
          <Typography variant='h6'>Terms and Conditions</Typography>
        </Grid>
        <Grid item xs={2} md={1}>
          <HookPercentField
            name='oneTimePercent'
            label='One-Time Percent'
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <HookPercentField
            name='recurringPercent'
            label='Recurring Percent'
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <HookPriceField
            name='takeRateCap'
            label='Take Rate Cap'
          />
        </Grid>
        <Grid item xs={8}></Grid>
        <Grid item xs={6}>
          <HookTextField
            multiline
            minRows={3}
            name='customConditions'
            label='Custom conditions'
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md='auto'>
        <HookCheckbox
          name='hipFireProvider'
          label='Hip Fire Provider?'
        />
      </Grid>
      <Grid item xs={12} md='auto'>
        <HookCheckbox
          name='nonStandardPricing'
          label='Non-standard pricing?'
        />
      </Grid>
      <Grid item xs={12} md='auto'>
        <HookCheckbox
          name='verifiedCustomers'
          label='Customers Uploaded?'
        />
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={12} md={3}>
          <FormControl variant='standard' fullWidth error={isError('streetFairProviderRating')}>
            <InputLabel htmlFor="status-type">StreetFair Provider Rating</InputLabel>
            <HookSelect
              control={formCtx.control}
              rules={{
                required: { value: true, message: 'Required' },
              }}
              name={'streetFairProviderRating'}
              id='streetfair-provider-rating'
              menuItems={streetFairRatingsMenuItems}
            />
            <FormHelperText>{getErrorMessage('streetFairProviderRating')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={2} md={1} lg={1} paddingLeft={'10px'} paddingTop={'8px'}>
          <HookIntegerField
            name='supplyQualitativeScore'
            label='Stoke Meter'
            validationRules={{
              min: { value: 1 || false, message: 'Minimum value of 1' },
              max: { value: 10 || false, message: 'Maximum value of 10' },
              validate: validateQualitativeScore,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <HookTextField
            name='approvedBy'
            label='StreetFair Representative ID'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ServiceProviderCrmCreatableAutocomplete />
        </Grid>
      </Grid>
    </Grid>
  );
}