import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { ILetterBatch, ILetterBatchPage, ISearchLetterBatchRequest } from 'model/letterBatch';
import { deleteLetterBatch, findLetterBatches } from 'api/letterBatchesApi';
import { useNavigate } from 'react-router-dom';
import { findMarkets } from 'api/marketsApi';
import { IMarket, MarketUtils } from 'model/markets';
import useToast from 'components/toast/useToast';

const loadingKey = 'LetterBatchTableStore';
type LetterBatchTableStore = {
  letterBatches: ILetterBatch[];
  availableCount: number;
}

const { get, update, registerListener, unregisterListener } = createStore<LetterBatchTableStore>('LetterBatchTableStore', {
  letterBatches: [],
  availableCount: 0,
});


export default function useLetterBatchTables() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const navigate = useNavigate();
  const { createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {


  }

  async function refresh(searchRequest: ISearchLetterBatchRequest):Promise<ILetterBatchPage> {
    let letterBatchPage:ILetterBatchPage = {
      letterBatches: [],
      availableCount: 0,
    };
    onLoading();
    try {
      let letterBatchesRes = await findLetterBatches(searchRequest);

      if (letterBatchesRes.data) {
        letterBatchPage = letterBatchesRes.data;
        update({
          ...get(),
          letterBatches: letterBatchesRes.data.letterBatches,
          availableCount: letterBatchesRes.data.availableCount,
        });
      } else {
        update({
          ...get(),
          letterBatches: [],
        });
      }


    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);

    return letterBatchPage;
  }


  async function onDeleteLetterBatch(id: string) {
    onLoading();
    try {
      await deleteLetterBatch(id);
      await init();
      createSuccessToast('letter batch deleted');
    } catch (e:any) {
      console.error(e);
      createErrorToast('Error deleting letter batch'+ e.response?.data?.message);
    }
    doneLoading(300);
  }

  function onAddNewBatch() {
    navigate('/letterBatches/new');
  }

  return {
    ...get(),
    loadingKey,
    init,
    onDeleteLetterBatch,
    onAddNewBatch,
    refresh,
  };
}