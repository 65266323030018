import { Badge, Grid } from '@mui/material';
import { updateServiceSummaries } from 'api/serviceProviderApi';
import { SecondaryButton } from 'components/buttons';
import ServiceSummaryConfirmationModal, { serviceSummaryConfirmationModalKey } from './ServiceSummaryConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { useEffect, useState } from 'react';
import useToast from 'components/toast/useToast';

export interface IServiceSummaryActionsProps {
  serviceProviderId: string;
}

const serviceSummaryCooldownStorageKey = 'serviceSummaryCooldown';

export default function ServiceSummaryActions({
  serviceProviderId,
}:IServiceSummaryActionsProps) {
  const { openModal } = useConfirmationModal(serviceSummaryConfirmationModalKey);
  const [cooldown, setCooldown] = useState(0);
  const { createInfoToast } = useToast();

  useEffect(() => {
    if (cooldown > 0) {
      const interval = setInterval(() => {
        if (cooldown - 1 <= 0) {
          localStorage.removeItem(serviceSummaryCooldownStorageKey);
        } else {
          localStorage.setItem(serviceSummaryCooldownStorageKey, (cooldown - 1).toString());
        }
        setCooldown(cooldown - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [cooldown]);

  useEffect(() => {
    const storedCooldown = localStorage.getItem(serviceSummaryCooldownStorageKey);
    if (storedCooldown && parseInt(storedCooldown) != 0) {
      setCooldown(parseInt(storedCooldown));
    }
  }, []);

  return (
    <Grid container item xs={12} justifyContent={'flex-end'} sx={{ padding: '20px 20px 100px 20px' }} spacing={2}>

      <Grid item xs='auto' >
        <Badge badgeContent={cooldown} color='primary' >
          <SecondaryButton
            type='button'
            onClick={() => {
              if (cooldown > 0) {
                createInfoToast(`Please wait ${cooldown} seconds before trying again`);
                return;
              }
              openModal({
                serviceProviderId: serviceProviderId,
                isLive: false,
              });
            } } >
          Sync service summaries (stale)
          </SecondaryButton>
        </Badge>
      </Grid>

      <Grid item xs='auto' >
        <Badge badgeContent={cooldown} color='primary' >
          <SecondaryButton
            type='button'
            onClick={() => {
              if (cooldown > 0) {
                createInfoToast(`Please wait ${cooldown} seconds before trying again`);
                return;
              }
              openModal({
                serviceProviderId: serviceProviderId,
                isLive: true,
              });
            } } >
          Sync service summaries (live)
          </SecondaryButton>
        </Badge>
      </Grid>
      {window.REACT_APP_NODE_ENV === 'local'&&
      <>
        <Grid item xs={12}>
          <a href={`http://localhost:3003/review-service-provider/${serviceProviderId}/neighborhood/2605a268-dabd-47b8-9569-0a2fb4d3d22b/individual/admin%2Bconsumer%40example.com/link/1959ec2c-e4fa-42cd-802d-6cf7eebf8dfb?mc=reviewProviderEmail&cid=0c037e6d-51a5-43e4-9fb2-e73ba82be4df`}
          >
          review service provider in Northstone
          </a>
        </Grid>
        <Grid item xs={12}>
          <a href={`http://localhost:3003/recommend-me-on-streetfair/service-providers/${serviceProviderId}/neighborhoods/2605a268-dabd-47b8-9569-0a2fb4d3d22b/`}
          >
          recommend me on streetfair link (Northstone)
          </a>
        </Grid>
      </>
      }
      <ServiceSummaryConfirmationModal
        onConfirm={async () => {
          const cooldownSeconds = 60;
          setCooldown(cooldownSeconds);
          localStorage.setItem(serviceSummaryCooldownStorageKey, cooldownSeconds.toString());
        }}
      />
    </Grid>
  );
}