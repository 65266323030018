import { useEffect, useState } from 'react';
import useInviteNeighborsHookStore from './inviteNeighborsHookStore';
import { columns } from './tableColumnConfig';
import useTableSearch from 'components/grid/useTableSearch';
import { Button, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import { DataGridPro, GridFilterModel, GridSelectionModel } from '@mui/x-data-grid-pro';
import { PrimaryButton } from 'components/buttons';
import { modalStoreKey } from './_components/SendInvitesModal';
import { modalStoreKey as markNoEmailModalStoreKey } from './_components/MarkNoEmailAvailableModal';
import useModal from 'components/modal/useModal';

const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
const quickSearchStorageKey = 'neighborConnectionsTable';


export default function InviteNeighborsTable() {
  const { neighborConnections } = useInviteNeighborsHookStore();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([
    {
      field: 'inviterFullName',
      sort: 'asc' as any,
    },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [filterOption, setFilterOption] = useState('all');
  const [selected, setSelected] = useState<any[]>([]);
  const { openModal: openSendInvitesModal } = useModal(modalStoreKey);
  const { openModal: openMarkNoEmailModal } = useModal(markNoEmailModalStoreKey);

  useEffect(() => {
    setRowsWithStoredFilter(neighborConnections);
  }, [neighborConnections]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.target.value;
    setFilterOption(selectedOption);

    let newFilterModel: GridFilterModel = { items: [] };

    if (selectedOption === 'inviteAvailable') {
      newFilterModel = {
        items: [
          {
            id: 1,
            columnField: 'contactEmail',
            operatorValue: 'isNotEmpty',
          },
          {
            id: 2,
            columnField: 'connectionStatus',
            operatorValue: 'equals',
            value: 'PENDING_INVITE',
          },
          {
            id: 3,
            columnField: 'noEmailAvailable',
            operatorValue: 'equals',
            value: 'false',
          },
        ],
      };
    } else if (selectedOption === 'alreadySent') {
      newFilterModel = {
        items: [
          {
            columnField: 'connectionStatus',
            operatorValue: 'equals',
            value: 'SENT',
          },
        ],
      };
    } else if (selectedOption === 'emptyEmail') {
      newFilterModel = {
        items: [
          {
            id: 1,
            columnField: 'contactEmail',
            operatorValue: 'isEmpty',
          },
          {
            id: 2,
            columnField: 'noEmailAvailable',
            operatorValue: 'equals',
            value: 'false',
          },
          {
            id: 3,
            columnField: 'connectionStatus',
            operatorValue: 'equals',
            value: 'PENDING_INVITE',
          },
        ],
      };
    }

    setFilterModel(newFilterModel);
  };

  var areFilterOptionsDisabled = neighborConnections.length === 0;
  var areButtonsDisabled = !selected || selected.length === 0;

  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid container item>
        <Grid item xs={4}>
          <RadioGroup row value={filterOption} onChange={handleFilterChange}>
            <FormControlLabel value="all" control={<Radio />} label="Show All" disabled={areFilterOptionsDisabled} />
            <FormControlLabel value="inviteAvailable" control={<Radio />} label="Email Invite Available" disabled={areFilterOptionsDisabled} />
            <FormControlLabel value="alreadySent" control={<Radio />} label="Already Sent" disabled={areFilterOptionsDisabled} />
            <FormControlLabel value="emptyEmail" control={<Radio />} label="Pending with No Email Present" disabled={areFilterOptionsDisabled} />
          </RadioGroup>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid item xs={3}>
            <PrimaryButton
              disabled={areButtonsDisabled}
              onClick={() => {
                openSendInvitesModal({ connectionIds: selected });
              }}
              color='error'
            >
              {areButtonsDisabled ? 'Send Invites' : `Send Invites (${selected.length})`}
            </PrimaryButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={areButtonsDisabled}
              variant="contained"
              color='warning'
              onClick={() => {
                openMarkNoEmailModal({ connectionIds: selected });
              }}
            >
              { areButtonsDisabled ? 'Mark as No Email Available' : `Mark as No Email Available (${selected.length})`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGridPro
          rows={rows}
          components={{ Toolbar: QuickSearchToolbar }}
          getRowId={(row) => row.connectionId}
          checkboxSelection
          componentsProps={{
            toolbar: {
              quickSearchStorageKey,
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(neighborConnections, event.target.value),
              clearSearch: () => requestSearch(neighborConnections, ''),
            },
          }}
          columns={columns}
          autoHeight={true}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          style={{
            minWidth: '500px',
            maxWidth: `${columnTotalWidth}px`,
          }}
          onSelectionModelChange={(selectionModel) => {
            setSelected(selectionModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(model) => setFilterModel(model)}
          pagination={true}
          pageSize={25}
        />
      </Grid>
    </Grid>
  );
}