import { Grid } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';

import InviteNeighborsTable from './InviteNeighborsTable';
import InviteNeighborsFilterBar from './InviteNeighborsFilterBar';
import SendInvitesModal from './_components/SendInvitesModal';
import MarkNoEmailAvailableModal from './_components/MarkNoEmailAvailableModal';

export default function InviteNeighbors() {
  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Invite Neighbors'} />
      </Grid>
      {/* <Grid item xs={12}>
        <SendEmailInviteCard />
      </Grid> */}
      <Grid item xs={12}>
        <InviteNeighborsFilterBar />
      </Grid>
      <Grid item xs={12}>
        <InviteNeighborsTable />
      </Grid>
      <Grid>
        <SendInvitesModal />
      </Grid>
      <Grid>
        <MarkNoEmailAvailableModal />
      </Grid>
    </Grid>

  );
}