import { Grid, Button, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Loading from 'components/Layout/Loading';
import Spacer from 'components/Layout/Spacer';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import { HookIntegerField, HookSwitch, HookTextField } from 'components/reactHookForm';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { INeighborhood, IInternalNeighborhood, NeighborhoodCommunicationType, NeighborhoodStatusType, NeighborhoodUtils, IInternalNeighborhoodForm } from 'model/neighborhood';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import NeighborhoodCommunicationForm from './neighborhoodCommunicationForm';
import styles from './neighborhoodDetail.module.css';
import NeighborhoodImage from './NeighborhoodImage';
import NeighborhoodImageDropzone from './NeighborhoodImageDropzone';
import ZipCodes from '../zipCodes';
import ReconcileNsosByNeighborhoodForm from './reconcileNsosByNeighborhoodForm';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';
import HookLargeIntegerField from 'components/reactHookForm/HookLargeIntegerField';
import LaunchLeaderTypeSelect from './formComponents/LaunchLeaderTypeSelect';
import NeighborhoodStatusSelect from './formComponents/NeighborhoodStatusSelect';
import NewsletterCadenceSelect from './formComponents/NewsletterCadenceSelect';
import useNeighborhoodDetail from './hookStore/useNeighborhoodDetail';
import MarketSelect from './formComponents/MarketSelect';
import TerritorySelect from './formComponents/TerritorySelect';
import useMarkets from 'hooks/useMarkets';
import { SecondaryButton } from 'components/buttons';
import RatingSelect from './formComponents/RatingSelect';
import HookDateRangePicker from 'components/reactHookForm/HookDateRangePicker';
import NeighborhoodExperiments from './NeighborhoodExperiments';
import NeighborhoodFacebookGroups from './NeighborhoodFacebookGroups';
import MiningInputs from './MiningInputs';


export interface INeighborhoodDetailsFormInternalProps {
  initialFormData: IInternalNeighborhoodForm;
}

function NeighborhoodDetailsFormInternal({
  initialFormData,
}:INeighborhoodDetailsFormInternalProps) {
  const {
    loadingKey,
    neighborhood,
    init,
    onSaveNeighborhood,
    onSendNeighborhoodCommunication,
    onReconcileNSOsByNeighborhood,
  } = useNeighborhoodDetail();
  const formContext = useForm<IInternalNeighborhoodForm>({
    mode: 'onChange',
    defaultValues: NeighborhoodUtils.createEmptyInternalNeighborhoodForm(),
  });
  const [communicationTemplates] = useState<any[]>(NeighborhoodCommunicationType.list);
  const navigate = useNavigate();

  const { neighborhoodId } = useParams();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const onSubmit : SubmitHandler<IInternalNeighborhoodForm> = data => {
    void onSaveNeighborhood(data);
  };

  const neighborhoodStatus = useWatch({ control: formContext.control, name: 'status' });

  return (
    <Loading key={loadingKey}>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid container spacing={2} className='pageGridContainer'>
            <Grid container item xs={12}>
              <PageHeader headerText={'Manage neighborhood'}/>
            </Grid>
            <Grid item md={2} xs={3}>
              <HookTextField
                name='name'
                label='Name'
                required
                maxLength={100}
              />
            </Grid>
            <Grid item md={2} xs={3}>
              <NeighborhoodStatusSelect/>
            </Grid>
            <Grid item md={2} xs={3}>
              <MarketSelect/>
            </Grid>
            <Grid item md={2} xs={3}>
              <TerritorySelect/>
            </Grid>
            <Grid item md={2} xs={3}>
              <HookDatePicker
                label='Launch Date'
                name='launchDate'
                textFieldProps={{ fullWidth: true, variant: 'standard' }}
                disabled={neighborhoodStatus !== NeighborhoodStatusType.LAUNCHED}
                required={neighborhoodStatus === NeighborhoodStatusType.LAUNCHED}
                dateFormats={{
                  fullDate: 'yyyy-MM-dd',
                }}
              />
            </Grid>
            <Grid item md={2} xs={3}>
              <NewsletterCadenceSelect/>
            </Grid>
            <Grid item md={1} xs={3}>
              <HookTextField
                name='inviteCode'
                label='Invite Code'
                maxLength={7}
              />
            </Grid>
            <Grid item md={2} xs={3}>
              <HookTextField
                name='onboardingCompletion'
                label='Onboarding Completion'
                validationRules={{
                  validate: function(value) {
                    if (value) {
                      const matched = !!value.toString().match(/^[0-9]{1,10}$/g);
                      if (!matched) {
                        return 'Must be an integer';
                      }
                      return true;
                    } else {
                      return true; //Not required!
                    }
                  },
                }}/>
            </Grid>
            <Grid item md={1} xs={3}>
              <HookTextField
                name='waitlistNumber'
                label='Waitlist Number'
                validationRules={{
                  validate: function(value) {
                    if (value) {
                      const matched = !!value.toString().match(/^[0-9]{1,10}$/g);
                      if (!matched) {
                        return 'Must be an integer';
                      }
                      return true;
                    } else {
                      return true; //Not required!
                    }

                  },
                }}/>
            </Grid>
            <Grid item md={2} xs={3}>
              <HookTextField
                name='startingNeighborCount'
                label='Starting Neighbor Count'
                validationRules={{
                  validate: function(value) {
                    if (value) {
                      const matched = !!value.toString().match(/^[0-9]{1,10}$/g);
                      if (!matched) {
                        return 'Must be an integer';
                      }
                      return true;
                    } else {
                      return true; //Not required!
                    }

                  },
                }}/>
            </Grid>
            <Grid item md={2} xs={2}>
              <HookTextField
                name='maxHappeningSoonCardCount'
                label='Max Happening Soon Card Count'
                validationRules={{
                  validate: function(value) {
                    if (value) {
                      const matched = !!value.toString().match(/^[0-9]{1,10}$/g);
                      if (!matched) {
                        return 'Must be an integer';
                      }
                      return true;
                    } else {
                      return true; //Not required!
                    }

                  },
                }}/>
            </Grid>
            <Grid item xs={2}>
              <HookIntegerField
                name='neighborhoodCommunications.happeningSoonDayThreshold'
                label='Happening Soon Alert Day Threshold'
              />
            </Grid>
            <Grid container spacing={1} item xs={12} alignItems='flex-start'>
              {/* <Grid item xs={3}>
                <HookTextField
                  name='zipCodeTerritory'
                  label='Territory Name'
                />
              </Grid> */}
              <Grid item md={1} xs={3}>
                <HookTextField
                  name='providerCoverageZone'
                  label='Provider Coverage Zone'
                />
              </Grid>
              <Grid item xs={1}>
                <RatingSelect />
              </Grid>
            </Grid>
            <Grid container spacing={1} item xs={12} >
              <Grid item xs={1}>
                <HookLargeIntegerField
                  name='houseHoldCount'
                  label='Household Count'
                />
              </Grid>
              <Grid item xs={2}>
                <LaunchLeaderTypeSelect/>
              </Grid>
              <Grid item xs={3}>
                <HookTextField
                  name='envelop'
                  label='Envelop'
                />
              </Grid>
              <Grid item xs={3}>
                <HookTextField
                  name='address'
                  label='Address'
                />
              </Grid>
            </Grid>
            <ZipCodes/>
            <Grid item xs={12}>
              <MiningInputs />
            </Grid>
            <Grid item xs={12}>
              <NeighborhoodExperiments />
            </Grid>
            <Grid item xs={12}>
              <NeighborhoodFacebookGroups />
            </Grid>
            <Grid item xs={12}>
              <HookTextField
                multiline
                name='neighborhoodGeometryWKT'
                label='Geometry WKT'
              />
            </Grid>
            <Grid item xs={6}>
              <NeighborhoodImageDropzone/>
            </Grid>
            <Grid item xs={12}>
              <NeighborhoodImage/>
            </Grid>
            <Spacer/>
            <Grid item xs={12}>
              <Grid container>
                <Button
                  variant="contained"
                  className={styles.saveButton}
                  type="submit"
                >
                  <span>Save</span>
                </Button>
                <Button
                  variant="text"
                  className={styles.cancelButton}
                  onClick={()=>{navigate('/neighborhoods');}}
                >
                  <span>Cancel</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>

      </FormProvider>

      <ConfirmationModalContextProvider onConfirm={(args) => onSendNeighborhoodCommunication(args.data) }>
        <NeighborhoodCommunicationForm menuItems={communicationTemplates} neighborhoodId={neighborhoodId}/>
        <ConfirmationModal
          title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Send neighborhood communication?</div>)}
          message={(onConfirmArgs:any) => (
            <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmDeleteModal}>
              <Grid item xs>
                Click confirm to send communication.
              </Grid>
            </Grid>
          )}
        />
      </ConfirmationModalContextProvider>
      <ConfirmationModalContextProvider onConfirm={(args) => onReconcileNSOsByNeighborhood(args) }>
        <ReconcileNsosByNeighborhoodForm neighborhood={neighborhood}/>
        <ConfirmationModal
          title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Reconcile NSOs for {neighborhood?.name}?</div>)}
          message={(onConfirmArgs:any) => (
            <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmNSOReconciliation}>
              <Grid item xs={12}>
                <Typography>{onConfirmArgs.dryRun ? 'Dry Run' : ''}</Typography>
              </Grid>
              <Grid item xs>
                Click confirm to initiate.
              </Grid>
            </Grid>
          )}
        />
      </ConfirmationModalContextProvider>
    </Loading>
  );
}

export default function NeighborhoodDetails() {
  const { formData, init } = useNeighborhoodDetail();
  const { market } = useMarkets();
  useEffect(() => {
    void init(market);
  }, [market]);
  return (
    <NeighborhoodDetailsFormInternal initialFormData={formData}/>
  );
}