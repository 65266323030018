import { Button, Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
import useLetterBatchDetail from './hookStore/useLetterBatchDetail';
import LetterBatchDetailForm from './LetterBatchDetailForm';
import CrudTable from 'components/dataGrid/CrudTable';
import { columns } from './tableColumnConfig';
import { PrimaryButton } from 'components/buttons';
import { useParams } from 'react-router-dom';

export interface ILetterBatchDetailProps {
  isNew:boolean;
}

const quickSearchStorageKey = 'letter_batch_neighborhood_table';
const deleteModalStoreKey = 'deleteLetterBatchNeighborhoodModal';

export default function LetterBatchDetail({
  isNew,
}:ILetterBatchDetailProps) {
  const { letterBatchId } = useParams();
  const { loadingKey, letterBatchNeighborhoods, onAddNewLetterBatchNeighborhood, onDeleteLetterBatchNeighborhood } = useLetterBatchDetail();

  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container>
        <Grid item xs={12}>
          <LetterBatchDetailForm isNew={isNew}/>
        </Grid>
        <Grid container item xs={12} className='pageGridContainer'>
          <Grid item xs={12} sx={{ marginBottom: '10px' }}>
            <PrimaryButton
              variant="contained"
              disabled={isNew}
              onClick={onAddNewLetterBatchNeighborhood}>
              <>Add Neighborhood Campaign</>
            </PrimaryButton>
          </Grid>
          <Grid item xs={12}>
            <CrudTable
              quickSearchStorageKey={quickSearchStorageKey}
              allDataRows={letterBatchNeighborhoods}
              columns={columns}
              defaultSortModel={[{ field: 'neighborhoodId', sort: 'asc' }]}
              enableEdit
              enableDelete
              deleteModalStoreKey={deleteModalStoreKey}
              createDetailRoute={(id) => `/letterBatches/${letterBatchId}/letterBatchNeighborhoods/${id}`}
              getId={(row) => row.id}
              getDesc={(row) => row.batchName}
              onDelete={onDeleteLetterBatchNeighborhood}
            />
          </Grid>
        </Grid>
      </Grid>

    </Loading>
  );
}