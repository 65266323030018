import { Grid, IconButton } from '@mui/material';
import { generateQRCode } from 'api/letterBatchesApi';
import { PrimaryButton } from 'components/buttons';
import { HookIntegerField, HookTextField } from 'components/reactHookForm';
import { useFormContext, useWatch } from 'react-hook-form';
import useLetterBatchNeighborhoodDetail from '../hookStore/useLetterBatchNeighborhoodDetail';
import { BigTooltip } from 'components/tooltip/BigTooltip';
import HelpIcon from '@mui/icons-material/Help';


export const download = (blob: Blob | MediaSource, filename: string) => {
  if (!window) {
    return;
  }
  const blobUrl = window.URL.createObjectURL(blob);
  const anchor = window.document.createElement('a');
  anchor.download = filename;
  anchor.href = blobUrl;
  anchor.click();
  window.URL.revokeObjectURL(blobUrl);
};

export interface IGenerateQRButtonProps {
  enabled:boolean;
}

export default function GenerateQRButton() {


  const formContext = useFormContext();
  const selectedNeighborhood = useWatch({ control: formContext.control, name: 'neighborhoodId' });
  const watchedMarketingCode = useWatch({ control: formContext.control, name: 'marketingCode' });
  const watchedCampaignType = useWatch({ control: formContext.control, name: 'campaignType' });
  const { formData, letterBatch } = useLetterBatchNeighborhoodDetail();
  //only doing this because the neighborhood autocomplete isn't setting the field to the id
  let neighborhoodName;
  let neighborhoodId;
  if (selectedNeighborhood?.optionValue) {
    neighborhoodId = selectedNeighborhood.optionValue;
    neighborhoodName = selectedNeighborhood.optionText;
  } else {
    neighborhoodId = selectedNeighborhood;
    neighborhoodName = formData.neighborhoodName;
  }

  return (
    <Grid container item spacing={1}>
      <Grid item xs='auto' sx={{ '&&&&': { maxWidth: '140px' } }}>
        <HookTextField name='marketingCode' label='Marketing Code' />
      </Grid>
      <Grid container item xs='auto' alignContent={'end'} sx={{ paddingBottom: '20px' }}>
        <PrimaryButton
          type='button'
          disabled={!neighborhoodId || !watchedMarketingCode}
          onClick={async () => {
            let res = await generateQRCode({
              width: 150,
              height: 150,
              neighborhoodId: neighborhoodId,
              marketingCode: watchedMarketingCode,
            });
            var sendDate = letterBatch.sendDate;
            download(res.data, `${watchedCampaignType}_${neighborhoodName}_${sendDate}_qr-code.png`);
          }}>
          Generate QR Code
        </PrimaryButton>
        <BigTooltip title={
          'Resulting qr code will have a templated name with the campaign type, neighborhood name, and letter batch send date in YYYY-MM-dd format. The file will automatically download.'
        }>
          <IconButton>
            <HelpIcon />
          </IconButton>
        </BigTooltip>
      </Grid>
    </Grid>
  );
}