import { Grid, Typography } from '@mui/material';
import useLetterBatchNeighborhoodDetail from './hookStore/useLetterBatchNeighborhoodDetail';
import useMarkets from 'hooks/useMarkets';
import { useEffect, useState } from 'react';
import { NeighborhoodLetterStatus } from 'model/letterBatch';
import { formatLongMonthDayYear } from 'util/dateUtil';

export default function LetterBatchNeighborhoodDetailMetadata() {
  const { formData, letterBatch } = useLetterBatchNeighborhoodDetail();
  const { marketMap } = useMarkets();
  let [marketName, setMarketName] = useState('');
  useEffect(() => {
    if (letterBatch.marketId) {
      let market = marketMap.get(letterBatch.marketId);
      if (market) {
        setMarketName(market.name);
      }
    }
  }, [letterBatch, marketMap]);
  let color = 'green';
  if (formData.neighborhoodLetterStatus === NeighborhoodLetterStatus.DRAFT) {
    color = '#bd2222';
  } else if (formData.neighborhoodLetterStatus === NeighborhoodLetterStatus.READY) {
    color = '#22bd50';
  } else if (formData.neighborhoodLetterStatus === NeighborhoodLetterStatus.SENT) {
    color = '#23678c';
  }
  return (
    <Grid item xs={12}>
      <Typography variant='h6' sx={{ fontWeight: 'bold', color: color }}>Status:  {NeighborhoodLetterStatus.getDisplayName(formData.neighborhoodLetterStatus) ?? 'N/A'}</Typography>
      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Batch Send Date: {formatLongMonthDayYear(letterBatch.sendDate)}</Typography>
      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Market: {marketName}</Typography>
      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Territory: {formData.territoryName}</Typography>
      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Neighborhood: {formData.neighborhoodName}</Typography>
      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Last Territory Prep Date:  {formData.lastPreparationDate ?? 'Not prepped'}</Typography>
      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Household Count:  {formData.householdCount ?? 'Not set'}</Typography>

    </Grid>
  );
}