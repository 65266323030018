import { PrimaryButton } from 'components/buttons';
import useLetterBatchNeighborhoodDetail from '../../hookStore/useLetterBatchNeighborhoodDetail';
import { getLetterTemplate } from 'api/letterBatchesApi';

export const download = (blob: Blob | MediaSource, filename: string) => {
  if (!window) {
    return;
  }
  const blobUrl = window.URL.createObjectURL(blob);
  const anchor = window.document.createElement('a');
  anchor.download = filename;
  anchor.href = blobUrl;
  anchor.click();
  window.URL.revokeObjectURL(blobUrl);
};

export default function DownloadLetterTemplateButton() {
  const { formData } = useLetterBatchNeighborhoodDetail();
  if (!formData.letterTemplateFileKey) {
    return null;
  }
  return (
    <PrimaryButton
      type='button'
      disabled={!formData.letterTemplateFileKey}
      onClick={async () => {
        if (formData.letterTemplateFileKey) {
          const fileRes = await getLetterTemplate(formData.letterTemplateFileKey);
          if (fileRes.data) {
            download(fileRes.data, formData.letterTemplateFileName!);
          }

        }

      }}
    ><>Download letter template</>
    </PrimaryButton>
  );
}