import { deSeedCustomer } from 'api/popularityApi';
import { useLoading } from 'components/Layout/Loading';
import SubtractButton from 'components/buttons/SubtractButton';
import useToast from 'components/toast/useToast';
import useNeighborhoodSeedingToolingStore from './useNeighborhoodSeedingToolingStore';
import { loadingKey } from './NeighborhoodSeedingTable';

export default function SubtractSeed({ params }) {
  const { selectedNeighborhood, tableRows, refreshTable } = useNeighborhoodSeedingToolingStore();
  const { createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  async function handleClick() {
    try {
      onLoading();
      await deSeedCustomer(params.providerId, selectedNeighborhood.id!, params.serviceType, 1);
      // The response won't include the updated count due to performance reasons.
      // We are still going to increment the count locally to inform the user that the action was successful.
      const indexOfRow = tableRows.findIndex((row) => row.providerId === params.providerId && row.serviceType === params.serviceType);
      tableRows[indexOfRow].totalCustomers -= 1;
      await refreshTable();
      doneLoading();
    } catch (error) {
      doneLoading();
      console.error('error unseeding customer', { error, params });
      createErrorToast(`Error removing seed customer: ${error}`);
    }
  }

  return (
    <SubtractButton
      tooltipTitle={'Subtract seed data'}
      onClick={handleClick}
    />
  );
}