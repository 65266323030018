export interface ISearchLetterBatchRequest {
  marketId:string | null;
  batchName:string | null;
  batchStatus:LetterBatchStatus | null;
  pageNumber: number | null;
  pageSize:number | null;
}

export interface IQRGenerationRequest {
  width:number;
  height: number;
  neighborhoodId:string;
  marketingCode:string;
}

export interface ILetterBatchPage {
  letterBatches:ILetterBatch[];
  availableCount:number;
}

export interface ILetterBatch {
  id:string | null;
  batchName:string;
  batchStatus:string;
  sendDate:Date;
  exportDate:Date | null;
  marketId:string | null;
  createDate:Date | null;
  updateDate:Date | null;
  deleteDate: Date | null;
}

export function createEmptyLetterBatch():ILetterBatch {
  return {
    id: null,
    batchName: '',
    batchStatus: LetterBatchStatus.DRAFT,
    sendDate: new Date(),
    exportDate: null,
    marketId: null,
    createDate: null,
    updateDate: null,
    deleteDate: null,
  };
}

export interface ILetterBatchFormDto {
  id:string | null;
  batchName:string;
  batchStatus:string;
  sendDate:string;
  exportDate:Date | null;
  marketId:string | null;
  createDate:Date | null;
  updateDate:Date | null;
  deleteDate: Date | null;
}

export function createEmptyLetterBatchFormDto():ILetterBatchFormDto {
  return {
    id: null,
    batchName: '',
    batchStatus: LetterBatchStatus.DRAFT,
    sendDate: '',
    exportDate: null,
    marketId: null,
    createDate: null,
    updateDate: null,
    deleteDate: null,
  };
}


export class LetterBatchStatus {
  static DRAFT = 'DRAFT';
  static EXPORTED = 'EXPORTED';
  static SENT = 'SENT';

  static list = [
    {
      id: LetterBatchStatus.DRAFT,
      name: 'Draft',
    },
    {
      id: LetterBatchStatus.EXPORTED,
      name: 'Exported',
    },
    {
      id: LetterBatchStatus.SENT,
      name: 'Sent',
    },
  ];
}

export class NeighborhoodLaunchType {
  static RESIDENT = 'RESIDENT';
  static MINING = 'MINING';
  static GENERIC = 'GENERIC';
  static HOME_OWNERS_ASSOCIATION = 'HOME_OWNERS_ASSOCIATION';

  static list = [
    {
      id: NeighborhoodLaunchType.RESIDENT,
      name: 'Resident',
    },
    {
      id: NeighborhoodLaunchType.MINING,
      name: 'Mining',
    },
    {
      id: NeighborhoodLaunchType.GENERIC,
      name: 'Generic',
    },
    {
      id: NeighborhoodLaunchType.HOME_OWNERS_ASSOCIATION,
      name: 'Home Owners Association',
    },
  ];
}

export class CampaignType {
  //also known as "mining"
  static LAUNCH_LEADER_ACQUISITION = 'LAUNCH_LEADER_ACQUISITION';
  static LAUNCH = 'LAUNCH';
  static FOLLOW_UP = 'FOLLOW_UP';


  static list = [
    {
      id: CampaignType.LAUNCH_LEADER_ACQUISITION,
      name: 'Mining',
    },
    {
      id: CampaignType.LAUNCH,
      name: 'Launch',
    },
    {
      id: CampaignType.FOLLOW_UP,
      name: 'Follow Up',
    },
  ];
}

export class ToAddressOption {
  static TO_MY_STREET = 'TO_MY_STREET';
  static TO_MY_NEIGHBORHOOD = 'TO_MY_NEIGHBORHOOD';
  static REAL_NAME = 'REAL_NAME';

  static list = [
    {
      id: ToAddressOption.TO_MY_STREET,
      name: 'To My Street',
    },
    {
      id: ToAddressOption.TO_MY_NEIGHBORHOOD,
      name: 'To My Neighborhood',
    },
    {
      id: ToAddressOption.REAL_NAME,
      name: 'Real Name',
    },
  ];
}

export class ReturnAddressOption {

  static LAUNCH_LEADER = 'LAUNCH_LEADER';
  static STREETFAIR_MARKET_HQ = 'STREETFAIR_MARKET_HQ';
  static NAME_ONLY = 'NAME_ONLY';
  static NO_RETURN_ADDRESS = 'NO_RETURN_ADDRESS';
  static OTHER = 'OTHER';


  static list = [
    {
      id: ReturnAddressOption.LAUNCH_LEADER,
      name: 'Launch Leader Address',
    },
    {
      id: ReturnAddressOption.STREETFAIR_MARKET_HQ,
      name: 'StreetFair HQ Address',
    },
    {
      id: ReturnAddressOption.NAME_ONLY,
      name: 'Only name',
    },
    {
      id: ReturnAddressOption.NO_RETURN_ADDRESS,
      name: 'None',
    },
    {
      id: ReturnAddressOption.OTHER,
      name: 'Other',
    },
  ];
}

export class EnvelopeType {
  static ROBOT_WRITTEN = 'ROBOT_WRITTEN';
  static HANDWRITTEN = 'HANDWRITTEN';
  static PRINTED = 'PRINTED';

  static list = [
    {
      id: EnvelopeType.ROBOT_WRITTEN,
      name: 'Robot Written',
    },
    {
      id: EnvelopeType.HANDWRITTEN,
      name: 'Handwritten',
    },
    {
      id: EnvelopeType.PRINTED,
      name: 'Printed',
    },
  ];
}

export class NeighborhoodLetterStatus {
  static DRAFT = 'DRAFT';
  static READY = 'READY';
  static SENT = 'SENT';

  static list = [
    {
      id: NeighborhoodLetterStatus.DRAFT,
      name: 'Draft',
    },
    {
      id: NeighborhoodLetterStatus.READY,
      name: 'Ready',
    },
    {
      id: NeighborhoodLetterStatus.SENT,
      name: 'Sent',
    },
  ];

  static getDisplayName(status:string):string {
    let found = NeighborhoodLetterStatus.list.find(x => x.id === status);
    return found ? found.name : status;
  }
}

export interface ILetterBatchNeighborhood {
  id:string;
  launchType:string;
  campaignType:string;
  campaignTag: string;
  letterTemplateFileKey:string | null;
  letterTemplateFileName:string | null;
  targetAddressCount:number | null;
  toAddressOption:string;
  returnAddressFirstName:string | null;
  returnAddressLastName:string | null;
  returnAddressStreetAddress:string | null;
  returnAddressCity:string | null;
  returnAddressState:string | null;
  returnAddressZip:string | null;
  envelopeType:string;
  neighborhoodLetterStatus:string;
  marketingCode:string | null;
  letterBatchId:string;
  territoryId:string;
  neighborhoodId:string;
  excludeExistingUsers: boolean | null;
  createDate:Date | null;
  updateDate:Date | null;
  deleteDate:Date | null;
}

export interface ILetterBatchNeighborhoodFormDto {
  id:string;
  launchType:string;
  campaignType:string;
  campaignTag: string;
  letterTemplateFileKey:string | null;
  letterTemplateFileName:string | null;
  targetAddressCount:number | null;
  toAddressOption:string;
  returnAddressFirstName:string | null;
  returnAddressLastName:string | null;
  returnAddressStreetAddress:string | null;
  returnAddressCity:string | null;
  returnAddressState:string | null;
  returnAddressZip:string | null;
  envelopeType:string;
  neighborhoodLetterStatus:string;
  marketingCode:string | null;
  letterBatchId:string;
  territoryId:string;
  territoryName:string;
  neighborhoodId:string;
  neighborhoodName:string;
  excludeExistingUsers: boolean | null;
  createDate:Date | null;
  updateDate:Date | null;
  deleteDate:Date | null;
  householdCount: number | null;
  lastPreparationDate: Date | null;
}

export function createEmptyLetterBatchNeighborhood():ILetterBatchNeighborhood {
  return {
    id: '',
    launchType: NeighborhoodLaunchType.RESIDENT,
    campaignType: CampaignType.LAUNCH_LEADER_ACQUISITION,
    campaignTag: '',
    letterTemplateFileKey: null,
    letterTemplateFileName: null,
    targetAddressCount: null,
    toAddressOption: ToAddressOption.TO_MY_STREET,
    returnAddressFirstName: null,
    returnAddressLastName: null,
    returnAddressStreetAddress: null,
    returnAddressCity: null,
    returnAddressState: null,
    returnAddressZip: null,
    envelopeType: EnvelopeType.ROBOT_WRITTEN,
    neighborhoodLetterStatus: NeighborhoodLetterStatus.DRAFT,
    marketingCode: null,
    letterBatchId: '',
    territoryId: '',
    neighborhoodId: '',
    excludeExistingUsers: false,
    createDate: null,
    updateDate: null,
    deleteDate: null,
  };
}

export function createEmptyLetterBatchNeighborhoodFormDto():ILetterBatchNeighborhoodFormDto {
  return {
    id: '',
    launchType: NeighborhoodLaunchType.RESIDENT,
    campaignType: CampaignType.LAUNCH_LEADER_ACQUISITION,
    campaignTag: '',
    letterTemplateFileKey: null,
    letterTemplateFileName: null,
    targetAddressCount: null,
    toAddressOption: ToAddressOption.TO_MY_STREET,
    returnAddressFirstName: null,
    returnAddressLastName: null,
    returnAddressStreetAddress: null,
    returnAddressCity: null,
    returnAddressState: null,
    returnAddressZip: null,
    envelopeType: EnvelopeType.ROBOT_WRITTEN,
    neighborhoodLetterStatus: NeighborhoodLetterStatus.DRAFT,
    marketingCode: null,
    letterBatchId: '',
    territoryId: '',
    territoryName: '',
    neighborhoodId: '',
    neighborhoodName: '',
    householdCount: null,
    excludeExistingUsers: false,
    createDate: null,
    updateDate: null,
    deleteDate: null,
    lastPreparationDate: null,
  };
}

export interface ISearchLetterBatchNeighborhoodRequest {
  letterBatchId:string;
  neighborhoodLetterStatus?:NeighborhoodLetterStatus;
  territoryId?:string | null;
  neighborhoodId?:string | null;
}

export interface ILetterBatchValidationResult {
  valid:boolean;
  message:string;
  letterBatchId:string | null;
}

export interface ILetterBatchNeighborhoodValidationResult {
  valid:boolean;
  message:string;
  letterBatchNeighborhoodId:string | null;
}