import { ReturnAddressOption } from 'model/letterBatch';
import EnumSelect from './EnumSelect';
import { Grid } from '@mui/material';

export default function ReturnAddressSelect() {

  return (
    <Grid item xs={12} >
      <EnumSelect
        required
        label='Return Address'
        fieldName='returnAddressOption'
        list={ReturnAddressOption.list}
      />
    </Grid>
  );
}