import { Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
import { useParams } from 'react-router-dom';
import LetterBatchNeighborhoodDetailForm from '../LetterBatchNeighborhoodDetailForm';
import useLetterBatchNeighborhoodDetail from '../hookStore/useLetterBatchNeighborhoodDetail';
import { useWatch } from 'react-hook-form';
import { HookCheckbox } from 'components/reactHookForm';
import { CampaignType } from 'model/letterBatch';

export default function ExcludeExistingUsers() {

  const watchCampaignType = useWatch({ name: 'campaignType' });
  if (watchCampaignType !== CampaignType.FOLLOW_UP) {
    return null;
  }
  return (
    <HookCheckbox name="excludeExistingUsers" label="Exclude existing users" />
  );
}