import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { ILetterBatchFormDto, ILetterBatchNeighborhood, ILetterBatchNeighborhoodFormDto, ISearchLetterBatchNeighborhoodRequest, createEmptyLetterBatchFormDto } from 'model/letterBatch';
import { deleteLetterBatchNeighborhood, exportLetterBatch, findLetterBatchFormById, findLetterBatchNeighborhoods, saveLetterBatch } from 'api/letterBatchesApi';
import { useNavigate, useParams } from 'react-router-dom';
import { findMarkets } from 'api/marketsApi';
import { IMarket, MarketUtils } from 'model/markets';
import { IDropdownOption } from 'model/dropdown';
import { createDateIgnoreTimezone, formatLocalServiceDate } from 'util/dateUtil';
import useToast from 'components/toast/useToast';
import useMarkets from 'hooks/useMarkets';

const loadingKey = 'LetterBatchDetailStore';
type LetterBatchDetailStore = {
  formData: ILetterBatchFormDto;
  marketName: string;
  markets: IMarket[];
  marketOptions: IDropdownOption[];
  letterBatchNeighborhoods: ILetterBatchNeighborhoodFormDto[];
}

const { get, update, registerListener, unregisterListener } =
createStore<LetterBatchDetailStore>('LetterBatchDetailStore', {
  formData: createEmptyLetterBatchFormDto(),
  markets: [],
  marketOptions: [],
  letterBatchNeighborhoods: [],
  marketName: '',
});


export default function useLetterBatchDetail() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const navigate = useNavigate();
  const { letterBatchId } = useParams();
  const { createSuccessToast, createErrorToast } = useToast();


  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        formData: createEmptyLetterBatchFormDto(),
        markets: [],
        marketOptions: [],
        marketName: '',
        letterBatchNeighborhoods: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function init(isNew:boolean, markets:IMarket[]) {
    onLoading();
    try {

      if (isNew) {
        const marketOptions = MarketUtils.convertToDropdownOptions(markets);
        update({
          ...get(),
          formData: createEmptyLetterBatchFormDto(),
          markets: markets,
          marketOptions: marketOptions,
        });
        doneLoading(300);
        return;
      } else {
        update({
          ...get(),
          markets: markets,
        });
        await onRefresh();
      }

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function onRefresh() {
    const { markets } = get();
    var marketMap = MarketUtils.convertToMap(markets);
    const resFuture = findLetterBatchFormById(letterBatchId!);
    let req: ISearchLetterBatchNeighborhoodRequest = {
      letterBatchId: letterBatchId!,
    };
    const letterBatchNeighborhoodResFuture = findLetterBatchNeighborhoods(letterBatchId!, req);
    let letterBatchNeighborhoods:ILetterBatchNeighborhoodFormDto[] = [];
    const allPromise = await Promise.all([
      resFuture,
      letterBatchNeighborhoodResFuture,
    ]);
    const [res, letterBatchNeighborhoodRes] = allPromise;
    if (letterBatchNeighborhoodRes.data) {
      letterBatchNeighborhoods = letterBatchNeighborhoodRes.data;
    }
    let nextData = res.data;
    nextData.sendDate = createDateIgnoreTimezone(nextData.sendDate).toISOString();
    if (res.data) {
      update({
        ...get(),
        formData: nextData,
        letterBatchNeighborhoods,
        marketName: marketMap.get(nextData.marketId ?? '')?.name ?? '',
      });
    }
    return allPromise;
  }

  async function onSave(data:ILetterBatchFormDto) {
    onLoading();
    data.sendDate = formatLocalServiceDate(data.sendDate);

    try {
      let res = await saveLetterBatch(letterBatchId ?? null, data);
      if (!res.data.valid && res.data.message) {
        createErrorToast('Could not save batch: '+res.data.message);
      } else if (res.data.valid && letterBatchId) {
        doneLoading(150);
        navigate('/letterBatches');
      } else if (res.data.valid && res.data.letterBatchId) {
        navigate(`/letterBatches/${res.data.letterBatchId}`);
      }
      doneLoading(150);
    } catch (e:any) {
      createErrorToast('An error occurred during save: '+e.response?.data?.message);
      doneLoading(150);
    }
  }

  function onAddNewLetterBatchNeighborhood() {
    navigate(`/letterBatches/${letterBatchId}/letterBatchNeighborhoods/new`);
  }

  async function onDeleteLetterBatchNeighborhood(id:string) {
    onLoading();

    try {
      await deleteLetterBatchNeighborhood(letterBatchId!, id);
      await onRefresh();
      createSuccessToast('Letter Batch Neighborhood deleted');
    } catch (e:any) {
      createErrorToast('An error occurred during delete: '+e.response?.data?.message);
    }
    doneLoading();
  }

  async function onExport() {
    onLoading();
    try {
      if (letterBatchId) {
        await exportLetterBatch(letterBatchId);
      }
    } catch (e:any) {
      createErrorToast('An error occurred during export: '+e.response?.data?.message);
    }
    doneLoading(150);
  }

  return {
    ...get(),
    loadingKey,
    init,
    onSave,
    onAddNewLetterBatchNeighborhood,
    onDeleteLetterBatchNeighborhood,
    onExport,
  };
}