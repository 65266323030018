import Axios, { AxiosResponse } from 'axios';
import { IPopularity } from 'model/popularity';

export function findAllPopularityByNeighborhood(neighborhoodId:string):Promise<AxiosResponse<IPopularity[]>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/popularity/v2/findAllByNeighborhood/${neighborhoodId}`);
}

export function seedCustomer(serviceProviderId:string, neighborhoodId:string, serviceType: string, count:number) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/popularity/v2/seedCustomer/serviceProvider/${serviceProviderId}/neighborhood/${neighborhoodId}/serviceType/${serviceType}?count=${count}`);
};

export function deSeedCustomer(serviceProviderId:string, neighborhoodId:string, serviceType: string, count:number) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/popularity/v2/deSeedCustomer/serviceProvider/${serviceProviderId}/neighborhood/${neighborhoodId}/serviceType/${serviceType}?count=${count}`);
}

export function seedCustomerFromReferral(serviceProviderId:string, nsoId:string) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/popularity/v2/seedCustomerFromReferral/serviceProvider/${serviceProviderId}/neighborhoodServiceOffering/${nsoId}`);
}