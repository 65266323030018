import { Grid, Button } from '@mui/material';
import useInviteNeighborsHookStore, { createEmptyNeighborConnectionsFiltersForm, INeighborConnectionsFiltersForm } from './inviteNeighborsHookStore';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';
import { FormProvider, useForm } from 'react-hook-form';
import NeighborConnectionStatusSelect from './_components/NeighborConnectionStatusSelect';

export default function InviteNeighborsFilterBar() {

  const {
    onSearch,
  } = useInviteNeighborsHookStore();

  const formContext = useForm<INeighborConnectionsFiltersForm>({
    mode: 'onChange',
    defaultValues: createEmptyNeighborConnectionsFiltersForm(),
  });

  const onSubmit =async () => {
    await onSearch(formContext.getValues());
  };
  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid container item xs={12}
            display={'flex'}
            justifyContent={'start'}
            style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={2}>
              <HookDatePicker
                label="Start Date"
                name="startDate"
                dateFormats={{
                  fullDate: 'yyyy-MM-dd',
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <HookDatePicker
                label="End Date"
                name="endDate"
                required
                dateFormats={{
                  fullDate: 'yyyy-MM-dd',
                }}
              />
            </Grid>
            <Grid xs={12} sm={2}>
              <NeighborConnectionStatusSelect />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                type='submit'
                fullWidth
              >
                    Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}