import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDropdownOption } from 'model/dropdown';
import { INeighborhoodSearchRequest, ISearchPage, IStreetFairSearchRequest } from 'model/search';
import debounce from 'lodash.debounce';
import { INeighborhood } from 'model/neighborhood';
import { searchNeighborhoods } from 'api/neighborhoodApi';
import useMarkets from 'hooks/useMarkets';

const loadingKey = 'NeighborhoodMultiAutocompleteStore';
type NeighborhoodMultiAutocompleteStore = {
  options: IDropdownOption[];

}

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodMultiAutocompleteStore>('NeighborhoodMultiAutocompleteStore', {
  options: [],

});

const debouncedSearchNeighborhoods = debounce(
  async (dto:IStreetFairSearchRequest, callback:any) => {
    try {
      const res = await searchNeighborhoods(dto);
      callback(res.data);
    } catch (err) {
      console.error(err);
    }

  }, 400);

export default function useNeighborhoodMultiAutocomplete() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { selectedMarket } = useMarkets();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        options: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function initDropdownOptions(formContext:any, dropdownOptions: IDropdownOption[]) {
    update(
      {
        ...get(),
        options: dropdownOptions,
      },
    );
  }

  async function initWithValue(
    formContext: any,
    fieldName: string,
    values:string[],
    statuses: string[],
    optionDescriptionFunc?: (data:INeighborhood) => string,
  ) {
    onLoading();
    const dto:INeighborhoodSearchRequest = {
      query: '',
      ids: values,
      pageNumber: 0,
      pageSize: 100,
      offset: 0,
      marketId: selectedMarket?.id,
      statuses: statuses,
    };
    debouncedSearchNeighborhoods(dto, (data) => {
      const page = data as ISearchPage<INeighborhood>;

      update({
        ...get(),
        options: page.items.filter(x => x.id && x.name).map((item) => {
          return {
            key: item.id!,
            optionText: optionDescriptionFunc ? optionDescriptionFunc(item) : item.name!,
            optionValue: item.id!,
            ancillary: item,
          };
        }),

      });
      let selectedOptions = page.items
        .filter((item) => values.indexOf(item.id!) > -1)
        .map((item) => item.id!);

      if (selectedOptions !== null) {
        formContext.setValue(fieldName, selectedOptions);
      }
      doneLoading();
    });
  }

  async function onSearchNeighborhoods(
    search:string,
    statuses:string[],
    currentSelected: string[],
    optionDescriptionFunc?: (data:INeighborhood) => string,
  ) {
    onLoading();
    const dto:INeighborhoodSearchRequest = {
      query: search,
      pageNumber: 0,
      pageSize: 100,
      offset: 0,
      marketId: selectedMarket?.id,
      statuses: statuses,
      ids: [],
    };
    var selectedOptions = getSelectedOptions(currentSelected);


    debouncedSearchNeighborhoods(dto, (data) => {
      const page = data as ISearchPage<INeighborhood>;
      let nextOptions = page.items
        .filter(x => x.id && x.name && (!currentSelected || currentSelected.indexOf(x.id) === -1))
        .map((item) => {
          return {
            key: item.id!,
            optionText: optionDescriptionFunc ? optionDescriptionFunc(item) : item.name!,
            optionValue: item.id!,
            ancillary: item,
          };
        });
      update({
        ...get(),
        options: [...selectedOptions, ...nextOptions],
      });
      doneLoading();
    });

  }

  function getSelectedOptions(currentSelected:string[]): IDropdownOption[] {
    const { options } = get();
    return options.filter((item) => currentSelected.indexOf(item.optionValue) > -1);
  }


  return {
    ...get(),
    onSearchNeighborhoods,
    initWithValue,
    initDropdownOptions,
  };
}