
import { useNavigate } from 'react-router-dom';
import useLetterBatchNeighborhoodDetail from './hookStore/useLetterBatchNeighborhoodDetail';
import useMarkets from 'hooks/useMarkets';
import { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import { HookTextField } from 'components/reactHookForm';
import useToast from 'components/toast/useToast';
import { CampaignType, ILetterBatchNeighborhoodFormDto, createEmptyLetterBatchNeighborhoodFormDto } from 'model/letterBatch';
import { useFormContext, useForm, FormProvider } from 'react-hook-form';
import StickyFormButtons from './components/StickyFormButtons';
import NeighborhoodSelect from './components/NeighborhoodSelect';
import EnumSelect from './components/EnumSelect';
import { LetterTemplateDropzone } from './components/file_upload/LetterTemplateDropzone';
import LetterBatchNeighborhoodDetailMetadata from './LetterBatchNeighborhoodDetailMetadata';
import LaunchTypeSelect from './components/LaunchTypeSelect';
import AddressCount from './components/AddressCount';
import ToAddressSelect from './components/ToAddressSelect';
import EnvelopeSelect from './components/EnvelopeSelect';
import StateAbbrSelect from './components/StateAbbrSelect';
import ReturnAddressSelect from './components/ReturnAddressSelect';
import { ClearButton, RemoveButton } from 'components/buttons';
import TooltipDrawerButton from 'components/drawer/TooltipDrawerButton';
import TooltipDrawer from 'components/drawer/TooltipDrawer';
import CampaignTag from './components/CampaignTag';
import ExcludeExistingUsers from './components/ExcludeExistingUsers';

export interface ILetterBatchNeighborhoodDetailFormProps {
  isNew:boolean;
}

export interface ILetterBatchNeighborhoodFormInternalProps {
  initialFormData: ILetterBatchNeighborhoodFormDto;
  isNew:boolean;
}

const returnAddressTooltipKey = 'returnAddressTooltipKey';

export const letterBatchNeighborhoodDetailModalKey = 'letterBatchNeighborhoodDetailModalKey';

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { onSave } = useLetterBatchNeighborhoodDetail();
  const { createErrorToast } = useToast();

  const navigate = useNavigate();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Letter Batch - Neighborhood Campaign'}
      body='Save changes?'
      modalKey={letterBatchNeighborhoodDetailModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' sx={{ paddingBottom: '20px' }}>
          <Grid item xs={12}>
            <Typography variant='body1'>Are you sure you want to save?</Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        try {
          await onSave(data);
        } catch (err:any) {
          createErrorToast('Unable to save letter batch - neighborhood campaign');
        }
      }}

    />
  );
}

function LetterBatchNeighborhoodFormInternal({ initialFormData, isNew }:ILetterBatchNeighborhoodFormInternalProps) {

  const { letterBatch } = useLetterBatchNeighborhoodDetail();
  const formContext = useForm<ILetterBatchNeighborhoodFormDto>({
    mode: 'onChange',
    defaultValues: createEmptyLetterBatchNeighborhoodFormDto(),
  });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);


  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container sx={{
          '&&&': {
            position: 'relative',
          },
        }}>
          <StickyFormButtons initialFormData={initialFormData}/>
          <Grid container spacing={2} className='pageGridContainer'>
            <Grid item xs={12}>
              <PageHeader headerText={'Letter Batch - Neighborhood Campaign'} />
            </Grid>
            {!isNew && (<Grid item xs={12}>
              <LetterBatchNeighborhoodDetailMetadata/>
            </Grid>
            )}
            {isNew && (
              <Grid item xs={12} sm={6} md={2} >
                <NeighborhoodSelect marketId={letterBatch.marketId}/>
              </Grid>
            )}
            <Grid container item xs={12} sm={4} spacing={1}
              alignItems={'start'}
              alignContent={'start'}
              justifyContent='start'

            >
              <Grid item xs={12} sm={6} >
                <EnumSelect
                  label='Campaign Type'
                  fieldName='campaignType'
                  list={CampaignType.list}
                />
              </Grid>
              <Grid container item xs={12} sm={6} alignContent={'end'}>
                <ExcludeExistingUsers/>
              </Grid>
              <AddressCount/>
              <LaunchTypeSelect/>

              <Grid item xs={12} sm={6}>
                <CampaignTag/>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={4} lg={2} spacing={1} alignContent={'start'}>
              <EnvelopeSelect/>
              <ToAddressSelect/>
            </Grid>
            <Grid container item xs={12} md={4} lg={4} spacing={1}>

              <Grid container item xs={12} sm={6}>
                <Grid item xs>
                  <ReturnAddressSelect/>
                </Grid>
                <Grid item xs='auto'>
                  <TooltipDrawerButton storeKey={returnAddressTooltipKey} />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>

              </Grid>
              <Grid item xs={12} sm={6} >
                <HookTextField
                  name='returnAddressFirstName'
                  label='First Name (e.g. John)'
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <HookTextField
                  name='returnAddressLastName'
                  label='Last Name (e.g. Doe)'
                />
              </Grid>
              <Grid item xs={12} >
                <HookTextField
                  name='returnAddressStreetAddress'
                  label='Street Address (e.g. 123 Main St.)'

                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <HookTextField
                  name='returnAddressCity'
                  label='City (e.g. Huntersville)'

                />
              </Grid>
              <Grid container item xs={12} sm={4}>
                <Grid item xs>
                  <StateAbbrSelect/>
                </Grid>
                <Grid item xs='auto'>
                  <ClearButton onClick={() => {
                    formContext.setValue('returnAddressState', '');
                  }}/>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <HookTextField
                  name='returnAddressZip'
                  label='ZIP code (e.g. 28078)'
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={2} md={0}></Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={12} sm={6} >
              <LetterTemplateDropzone/>
            </Grid>
          </Grid>
          <ModalWrapper/>
          <TooltipDrawer
            storeKey={returnAddressTooltipKey}
          >
            <Box sx={{ padding: '20px', maxWidth: '500px' }}>
              <Typography variant='body1'>Depending on the choice of return address the campaign may be set back to draft status or promoted to ready.</Typography>

              <Typography variant='subtitle1'sx={{ paddingTop: '20px', fontWeight: 'bold' }}>Requirements for each option to enable promotion to ready status</Typography>
              <Typography variant='body1' sx={{ paddingTop: '20px' }}>Launch Leader Address:</Typography>
              <ul>
                <li>First Name: required</li>
                <li>Last Name: required</li>
                <li>Street Address: required</li>
                <li>City: required</li>
                <li>State: required</li>
                <li>Zip: required</li>
              </ul>
              <Typography variant='body1' sx={{ paddingTop: '20px' }}>StreetFair HQ Address:</Typography>
              <ul>
                <li>First Name: set to "StreetFair"</li>
                <li>Last Name: empty</li>
                <li>Street Address: required</li>
                <li>City: required</li>
                <li>State: required</li>
                <li>Zip: required</li>
              </ul>
              <Typography variant='body1' sx={{ paddingTop: '20px' }}>Only Name:</Typography>
              <ul>
                <li>First Name: required</li>
                <li>Last Name: required</li>
                <li>Street Address: empty</li>
                <li>City: empty</li>
                <li>State: empty</li>
                <li>Zip: empty</li>
              </ul>
              <Typography variant='body1' sx={{ paddingTop: '20px' }}>None:</Typography>
              <ul>
                <li>First Name: empty</li>
                <li>Last Name: empty</li>
                <li>Street Address: empty</li>
                <li>City: empty</li>
                <li>State: empty</li>
                <li>Zip: empty</li>
              </ul>
              <Typography variant='body1' sx={{ paddingTop: '20px' }}>Other:</Typography>
              <ul>
                <li>First Name: any</li>
                <li>Last Name: any</li>
                <li>Street Address: any</li>
                <li>City: any</li>
                <li>State: any</li>
                <li>Zip: any</li>
              </ul>
            </Box>
          </TooltipDrawer>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function LetterBatchNeighborhoodDetailForm({ isNew }: ILetterBatchNeighborhoodDetailFormProps) {
  const { formData, init } = useLetterBatchNeighborhoodDetail();
  const { markets } = useMarkets();
  useEffect(() => {
    if (isNew && markets.length === 0) {
      return;
    }
    void init(isNew, markets);
  }, [isNew, markets]);
  return (
    <LetterBatchNeighborhoodFormInternal initialFormData={formData} isNew={isNew}/>
  );
}