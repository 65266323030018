
/**
 * creates validator function with given message. Pass to the validate property of the rules object passed to a form input with react hook form
 * Must be a number without letters or other characters (except '.' as in 3.2)
 * @param message custom message to show. a default is provided.
 * @returns
 */
export function createValidateNumber(message?:string) {
  return function(value:any) {
    const isValid = !isNaN(value - parseFloat(value));
    if (!isValid) {
      return message || 'Must be a number (no letters or $ or %)';
    }
    return true;
  };
}

export function createValidateNumberNotRequired(message?:string) {
  return function(value:any) {
    const isValid = value === '' || value === null || !isNaN(value - parseFloat(value));
    if (!isValid) {
      return message || 'Must be a number (no letters or $ or %)';
    }
    return true;
  };
}

export function createValidateIntegerRange(min:number, max:number, message?:string) {
  return function(value:any) {
    const isValid = value === '' || value === null || (!isNaN(value - parseInt(value)) && value >= min && value <= max);
    if (!isValid) {
      return message || `Must be an integer (no decimal) between ${min} and ${max}`;
    }
    return true;
  };
}
/**
 * creates validator function with given message. Pass to the validate property of the rules object passed to a form input with react hook form.
 * Does basic validation of the structure of an email. True email validation still requires a test email
 * @param message
 * @returns
 */
export function createValidateEmail(message?:string) {
  return function(value:any) {
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    if (!isEmail) {
      return message || 'Not a valid email';
    }
    return true;
  };
}

export function createValidateEmailNotRequired(message?:string) {
  return function(value:any) {
    if (!value) {
      return true;
    }
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    if (!isEmail) {
      return message || 'Not a valid email';
    }
    return true;
  };
}


/**
 * creates validator function with given message. Pass to the validate property of the rules object passed to a form input with react hook form.
 *
 * @param message
 * @returns
 */
export function createValidatePhone(message?:string) {
  return function(value:any) {
    const isPhone = /^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/.test(value);
    const isAltPhone = /^(\([0-9]{3}\)\s*|[0-9]{3})[0-9]{3}[0-9]{4}$/.test(value);
    if (!isPhone && !isAltPhone) {
      return message || 'Not a valid phone number';
    }
    return true;
  };
}
