import { Grid, Typography } from '@mui/material';

import { ITerritoryForm, createEmptyTerritoryForm } from 'model/territories';
import useProviderCoverageArea from 'pages/providerCoverageArea/hookStore/useProviderCoverageArea';
import { useEffect } from 'react';
import { useForm, useWatch, FormProvider, useFormContext } from 'react-hook-form';
import SubmitButton from './formComponents/SubmitButton';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useTerritoryDetails from './hookStore/useTerritoryDetails';
import useMarkets from 'hooks/useMarkets';
import { HookTextField } from 'components/reactHookForm';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from 'components/buttons';
import TerritoryStatusSelect from './formComponents/TerritoryStatusSelect';
import useToast from 'components/toast/useToast';
import MarketSelect from './formComponents/MarketSelect';
import SupplyAcquisitionTargetSelect from './formComponents/SupplyAcquisitionTargetSelect';
import NewsletterCadenceSelect from './formComponents/NewsletterCadenceSelect';
import TerritoryPrepButton from './territoryPrep/TerritoryPrepButton';
import NonUserNewsletterCadenceSelect from './formComponents/NonUserNewsletterCadenceSelect';
import TerritoryCommunicationsInputs from './formComponents/TerritoryCommunicationsInputs';

export interface ITerritoryFormProps {
  isNew:boolean;
}

export interface ITerritoryFormInternalProps {
  initialFormData: ITerritoryForm;
}

export const territoryDetailsModalKey = 'territoryDetailsModalKey';

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { save } = useTerritoryDetails();
  const { createErrorToast } = useToast();

  const navigate = useNavigate();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Territory'}
      body='Save changes?'
      modalKey={territoryDetailsModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12}>
            <Typography variant='body1'>Any changes to ZIP code territory will not be reflected to Neighborhoods</Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        try {
          await save(data);
          navigate('/territories');
        } catch (err:any) {
          createErrorToast('Unable to save territory');
        }
      }}

    />
  );
}

function TerritoryFormInternal({ initialFormData }:ITerritoryFormInternalProps) {
  const navigate = useNavigate();
  const formContext = useForm<ITerritoryForm>({
    mode: 'onChange',
    defaultValues: createEmptyTerritoryForm(),
  });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);


  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container spacing={1} >
          <Grid item xs={12} sm={6} md={2}>
            <HookTextField name='name' label='Name' required/>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <HookTextField name='zipCodeTerritory' label='Zip Code Territory' required/>
          </Grid>
          <Grid item xs={12} sm={6} md={2} >
            <TerritoryStatusSelect/>
          </Grid>
          <Grid item xs={12} sm={6} md={2} >
            <SupplyAcquisitionTargetSelect/>
          </Grid>
          <Grid item xs={12} sm={6} md={2} >
            <MarketSelect/>
          </Grid>
          <Grid item xs={12}>
            <HookTextField
              multiline
              name='territoryGeometryWKT'
              label='Geometry WKT'
            />
          </Grid>
          <TerritoryCommunicationsInputs/>
          <Grid container item xs={12} alignItems='center' sx={{ marginTop: '10px' }}>
            <Grid item xs='auto' sx={{ marginRight: '10px' }}>
              <SubmitButton/>
            </Grid>
            <Grid item xs='auto' sx={{ marginRight: '10px' }}>
              <SecondaryButton
                onClick={() => {
                  formContext.reset(initialFormData);
                  navigate('/territories');
                }}>
                <>Cancel</>
              </SecondaryButton>
            </Grid>
            <Grid item xs='auto'>
              <TerritoryPrepButton/>
            </Grid>
          </Grid>
          <ModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function TerritoryForm({ isNew }: ITerritoryFormProps) {
  const { formData, init } = useTerritoryDetails();
  const { market } = useMarkets();
  useEffect(() => {
    if (market) {
      void init(market.id, isNew);
    }
  }, [market, isNew]);
  return (
    <TerritoryFormInternal initialFormData={formData}/>
  );
}