import { createValidatePhone, createValidateEmail } from 'util/rhfValidateFunctions';
import { Grid, Typography } from '@mui/material';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import AdditionalPhoneNumbers from './AdditionalPhoneNumbers';
import AdditionalContacts from './AdditionalContacts';
import { PrimaryButton } from 'components/buttons';
import useToast from 'components/toast/useToast';
import { generateProviderSignUpLink } from 'api/userApi';
import { useParams } from 'react-router-dom';
import HookPhoneField from 'components/reactHookForm/HookPhoneField';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';


const validateEmail = createValidateEmail();
const validatePhone = createValidatePhone('Should be of form xxx-xxx-xxxx or xxxyyyzzzz');

export default function ContactInfo() {
  const { createErrorToast, createInfoToast } = useToast();
  const { serviceProviderId } = useParams();
  return (
    <Grid container item spacing={1} xs={12}
      sx={{
        border: '1px solid gainsboro',
        marginLeft: '12px',
        marginTop: '20px',
      }}
    >
      <Grid container alignItems='center' item xs={12}>
        <Grid item xs='auto'>
          <Typography variant='h6'>Contact information</Typography>
        </Grid>
        <Grid item xs='auto' sx={{ paddingLeft: '20px' }}>
          <PrimaryButton
            size='small'
            disabled={!serviceProviderId}
            onClick={ async () => {
              var res = await generateProviderSignUpLink(serviceProviderId!);
              if (res.data) {
                if (res.data.success) {
                  var link = res.data.oneTimeSignUpLink;
                  if (link) {
                    createInfoToast(`Sign up link generated and automatically copied to clipboard: ${link}`);
                    navigator.clipboard.writeText(link).catch(reason => createErrorToast(reason));
                  } else {
                    createErrorToast('Unable to generate sign up link');
                  }
                } else {
                  createErrorToast(`Unable to generate sign up link with message: ${res.data.errorMessage}`);
                }

              }

            }}
          >
            <>Generate Sign Up Link</>
          </PrimaryButton>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <HookTextField
          label='Contact Name'
          name='contactPersonName'
          required
        />
      </Grid>
      <Grid item xs={2}>
        <HookPhoneField
          label='Booking Phone Number'
          name='bookingPhone'
        />
      </Grid>
      <Grid item xs={2}>
        <HookCheckbox
          label='Phone Booking Opted Out'
          name='phoneBookingOptedOut'
        />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid container item xs={12}>

        <Grid item xs={6} md={6} lg={4}>
          <AdditionalPhoneNumbers/>
        </Grid>
        <Grid item xs={6} md={6} lg={4}>
          <AdditionalContacts/>
        </Grid>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={2}>
        <HookPhoneField
          label='Support Contact Phone'
          name='businessContactPhone'
          required
          validationRules={{
            validate: validatePhone,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <HookTextField
          label='Support Contact Email'
          name='businessContactEmail'
          required
          validationRules={{
            validate: validateEmail,
          }}
        />
      </Grid>
      <Grid container item spacing={1} paddingBottom={'15px'}>
        <Grid item xs={6} md={6} lg={4}>
          <HookDatePicker
            label='Pause Notifications Until Date'
            name='pauseNotificationsUntilDate'
            textFieldProps={{ fullWidth: true, variant: 'standard' }}
            required={false}
            dateFormats={{
              fullDate: 'yyyy-MM-dd',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}