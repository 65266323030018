import { Button } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';
import { ITerritoryForm } from 'model/territories';
import { PrimaryButton } from 'components/buttons';
import { letterBatchDetailModalKey } from '../LetterBatchDetailForm';
import { ILetterBatchFormDto } from 'model/letterBatch';


export default function SubmitButton() {
  const { handleSubmit, setError, clearErrors, formState: { isSubmitting, isValid, errors }, getValues } = useFormContext();
  const { openModal } = useConfirmationModal(letterBatchDetailModalKey);
  const validate = (data:ILetterBatchFormDto) => {
    return {
      isValid: true,
    };;
  };
  const onSubmit : SubmitHandler<ILetterBatchFormDto> = data => {
    const { isValid: _isValid } = validate(data);
    if (_isValid) {
      openModal(data);
    }
  };
  const onSubmitError: SubmitErrorHandler<FieldValues> = data => {
    const { isValid: _isValid } = validate(getValues() as ILetterBatchFormDto);
    if (_isValid) {
      openModal(getValues());
    }
  };
  return (
    <PrimaryButton
      disabled={isSubmitting || !isValid}
      type='button'
      onClick={(e) => {
        // TODO: figure out why this onSubmit gives me inconsistent ts errors
        // @ts-expect-error
        void handleSubmit(onSubmit, onSubmitError)(e);
      }}
    >
            Save
    </PrimaryButton>
  );
}