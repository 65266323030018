import { Grid } from '@mui/material';
import { HookIntegerField } from 'components/reactHookForm';
import HookLargeIntegerField from 'components/reactHookForm/HookLargeIntegerField';
import { CampaignType } from 'model/letterBatch';
import { useFormContext, useWatch } from 'react-hook-form';

export default function AddressCount() {
  const formContext = useFormContext();
  const val = useWatch({ name: 'campaignType', control: formContext.control });

  if (val === CampaignType.LAUNCH_LEADER_ACQUISITION || val === CampaignType.FOLLOW_UP) {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <HookLargeIntegerField
          name='targetAddressCount'
          label='Address Count'
          required={val === CampaignType.LAUNCH_LEADER_ACQUISITION}
        />
      </Grid>
    );
  }
  return null;
}