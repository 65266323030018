export function getAllServiceProviderPhoneNumbersList(data:any) {
  var phoneNumberSet = new Set<string>();

  if (data.transientAdditionalContactPhones && data.transientAdditionalContactPhones.length > 0) {
    data.transientAdditionalContactPhones.forEach(x => {
      phoneNumberSet.add(x.value);
    });
  }

  if (data.contactPersonPhone && data.contactPersonPhone.length > 0) {
    phoneNumberSet.add(data.contactPersonPhone);
  }

  if (data.bookingPhoneNumber && data.bookingPhoneNumber.length > 0) {
    phoneNumberSet.add(data.bookingPhoneNumber);
  }

  if (data.businessContactPhone && data.businessContactPhone.length > 0) {
    phoneNumberSet.add(data.businessContactPhone);
  }

  var phoneNumbersArray = Array.from(phoneNumberSet);
  return phoneNumbersArray;
}