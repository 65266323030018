import { Grid, Alert, Typography, Button, IconButton, Tooltip } from '@mui/material';

import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import styles from './letterTemplateDropzone.module.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useLetterBatchNeighborhoodDetail from '../../hookStore/useLetterBatchNeighborhoodDetail';
import GenerateQRButton from '../GenerateQRButton';
import DownloadLetterTemplateButton from './DownloadLetterTemplateButton';

function sizeValidator(file:File) {
  if (file.size > 2_000_000) {
    return {
      code: 'file-too-large',
      message: 'The file is larger than 2MB',
    };
  }
  return null;
}

export function LetterTemplateDropzone() {

  const [errorMessage, setErrorMessage] = useState('');
  const { updateFile, removeFile, file, formData } = useLetterBatchNeighborhoodDetail();
  const onDrop = acceptedFiles => {
    acceptedFiles.map(f => Object.assign(f, {
      preview: URL.createObjectURL(f),
    }));
    updateFile(acceptedFiles[0]);
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    validator: sizeValidator,
    maxFiles: 10,
    multiple: true,
  });


  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      if (file !== null) {
        URL.revokeObjectURL(file.preview);
      }

    };
  }, []);


  const acceptedFileItems = [file].filter(x => x !== null).map(f => (
    <li key={f!.name}>
      {f!.name} - {f!.size / 1000} KB
      <IconButton onClick={e => removeFile()}>
        <DeleteOutlineIcon/>
      </IconButton>
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file: f, errors }) => {
    console.log(f);
    return (
      <li key={f.name}>
        {f.name} - {f.size / 1000} KB
        <ul>
          {errors.map(e => <li key={e.code}>{e.message}</li>)}
        </ul>
      </li>
    );
  });

  return (
    <Grid container justifyContent='center' className={styles.serviceOfferingDropzoneWrapper}>

      <Grid container item xs={12} justifyContent={'space-between'}>
        <Grid item xs={'auto'} sx={{ marginTop: '12px' }}>
          <Typography variant='h6'>Upload file</Typography>
          <DownloadLetterTemplateButton/>

          <Typography variant='caption' component='div' sx={{ paddingTop: '10px' }}>Uploaded file: {formData.letterTemplateFileName}</Typography>
        </Grid>
        <Grid item xs='auto'>
          <GenerateQRButton />
        </Grid>
      </Grid>
      <Grid item xs>
        <div {...getRootProps()} className={styles.dropzoneWrapper}>
          <input {...getInputProps()} accept='.pdf,.docx'/>
          {
            isDragActive ?
              <p>Drop file here ...</p> :
              <p>Drag and drop a file or click here to select a file. Accepts .pdf or .docx. Max file size 5MB.</p>
          }
        </div>
        {acceptedFileItems.length > 0 && (
          <div >
            <Typography variant='h6'>Accepted Files</Typography>
            <ul>
              {acceptedFileItems}
            </ul>
          </div>)}

        {fileRejectionItems.length > 0 && (
          <div >
            <Typography variant='h6'>Rejected Files</Typography>
            <ul>
              {fileRejectionItems}
            </ul>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        {errorMessage && (
          <Alert
            severity='error'
            className={styles.uploadErrorMessage}
            variant='outlined'
          >
            {errorMessage}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}