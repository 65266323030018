import { Grid, Alert, Typography, Button, IconButton, Tooltip } from '@mui/material';

import { useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import styles from './serviceOfferingImageUpload.module.css';
import Thumbnails from './Thumbnails';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useProviderSetupStore from 'pages/providerSetup/useProviderSetupStore';

function sizeValidator(file:File) {
  if (file.size > 5_000_000) {
    return {
      code: 'file-too-large',
      message: 'The file is larger than 5MB',
    };
  }
  return null;
}

export function ServiceOfferingImageDropzone({ index }:any) {
  const [errorMessage, setErrorMessage] = useState('');

  const { files, updateFiles, removeFile } = useProviderSetupStore();


  const onDrop = acceptedFiles => {
    if (acceptedFiles.length === 0) return;
    if (acceptedFiles.filter(x => x.type === 'image/webp').length > 0) {
      setErrorMessage('WebP format is not supported. Please upload a PNG, JPEG, or SVG file.');
      return;
    } else {
      setErrorMessage('');
    }

    acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
    }));
    updateFiles(acceptedFiles);
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    validator: sizeValidator,
    maxFiles: 10,
    multiple: true,
  });


  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);


  const acceptedFileItems = files.map(file => (
    <li key={file.name}>
      {file.name} - {file.size / 1000} KB
      <IconButton onClick={e => removeFile(file)}>
        <DeleteOutlineIcon/>
      </IconButton>
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.name}>
        {file.name} - {file.size / 1000} KB
        <ul>
          {errors.map(e => <li key={e.code}>{e.message}</li>)}
        </ul>
      </li>
    );
  });

  return (
    <Grid container justifyContent='center' className={styles.serviceOfferingDropzoneWrapper}>
      <Grid item xs={12} sx={{ marginTop: '12px' }}>
        <Typography variant='h6'>Upload images </Typography>
      </Grid>
      <Grid item xs>
        <div {...getRootProps()} className={styles.dropzoneWrapper}>
          <input {...getInputProps()} accept='.png,.jpeg,.svg'/>
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag and drop a file or click here to select a file. Accepts .png, .jpeg, or .svg. Max file size 5MB. Max request size 12MB.</p>
          }
        </div>
        <div >
          <Typography variant='h6'>Accepted Files</Typography>
          <ul>
            {acceptedFileItems}
          </ul>
        </div>
        <Thumbnails files={files}/>
        <div >
          <Typography variant='h6'>Rejected Files</Typography>
          <ul>
            {fileRejectionItems}
          </ul>
        </div>
      </Grid>
      <Grid item xs={12}>
        {errorMessage && (
          <Alert
            severity='error'
            className={styles.uploadErrorMessage}
            variant='outlined'
          >
            {errorMessage}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}