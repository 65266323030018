import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface IEnumSelectProps {
  label: string;
  fieldName:string;
  list: {id: string; name: string}[];
  enabled?: boolean;
  required?: boolean;
}

export default function EnumSelect({
  label,
  fieldName,
  list,
  enabled = true,
  required = false,
}:IEnumSelectProps) {
  const formCtx = useFormContext();
  const [options] = useState<any[]>(list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError(fieldName)}>
      <InputLabel htmlFor={`${fieldName}-select`}>{label}</InputLabel>
      <HookSelect
        rules={{ required: required ? 'Required' : false }}
        disabled={!enabled}
        control={formCtx.control}
        name={fieldName}
        id={`${fieldName}-select`}
        menuItems={options}
      />
      <FormHelperText>{getErrorMessage(fieldName)}</FormHelperText>
    </FormControl>
  );
}