import { IDropdownOption } from './dropdown';

export interface ITerritory {
  id: string;
  marketId:string;
  name: string;
  zipCodeTerritory:string;
  status: TerritoryStatus;
  territoryGeometryWKT: string | null;
  supplyAcquisitionTargetlevel: SupplyAcquisitionTargetLevel | null;
  newsletterCadence: string | null;
  nonUserNewsletterCadence: string | null;
}

export interface ITerritoryForm {
  id:string | null;
  name: string;
  zipCodeTerritory:string;
  status: TerritoryStatus;
  marketId:string;
  territoryGeometryWKT: string | null;
  supplyAcquisitionTargetlevel: SupplyAcquisitionTargetLevel | null;
  newsletterCadence: string | null;
  nonUserNewsletterCadence: string | null;
}

export function createUnassignedTerritoryOption():IDropdownOption {
  return {
    key: 'UNASSIGNED',
    optionValue: 'UNASSIGNED',
    optionText: 'Unassigned',
    ancillary: null,
  };
}

export function createEmptyTerritoryForm(marketId?:string):ITerritoryForm {
  return {
    id: null,
    name: '',
    zipCodeTerritory: '',
    status: TerritoryStatus.INACTIVE,
    marketId: marketId ?? '',
    territoryGeometryWKT: '',
    supplyAcquisitionTargetlevel: null,
    newsletterCadence: '',
    nonUserNewsletterCadence: null,
  };
}

export class TerritoryStatus {
  static get ACTIVE():string {
    return 'ACTIVE';
  }
  static get INACTIVE():string {
    return 'INACTIVE';
  }


  static list = [
    { id: TerritoryStatus.ACTIVE, name: 'Active' },
    { id: TerritoryStatus.INACTIVE, name: 'Inactive' },
  ];
}

export class SupplyAcquisitionTargetLevel {
  static get LIGHT():string {
    return 'LIGHT';
  }
  static get BASIC():string {
    return 'BASIC';
  }
  static get SOLID():string {
    return 'SOLID';
  }
  static get FULL():string {
    return 'FULL';
  }


  static list = [
    { id: SupplyAcquisitionTargetLevel.LIGHT, name: 'Light' },
    { id: SupplyAcquisitionTargetLevel.BASIC, name: 'Basic' },
    { id: SupplyAcquisitionTargetLevel.SOLID, name: 'Solid' },
    { id: SupplyAcquisitionTargetLevel.FULL, name: 'Full' },
  ];
}