import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { RemoveButton } from 'components/buttons';
import { HookSelect } from 'components/reactHookForm';
import { MetadataConstants } from 'constants/MetadataConstants';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { StateAbbreviations } from 'model/markets';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { sortByString } from 'util/sortFunctions';

const sortByName = sortByString('name');


export default function StateAbbrSelect() {
  const formCtx = useFormContext();
  const [states] = useState<any[]>(StateAbbreviations.list.sort(sortByName));
  const { isError, getErrorMessage } = useErrorState(formCtx);

  return (
    <>
      <FormControl variant='standard' fullWidth error={isError('returnAddressState')}>
        <InputLabel htmlFor="returnAddressState">State</InputLabel>
        <HookSelect
          control={formCtx.control}
          name='returnAddressState'
          id='returnAddressState'
          menuItems={states}
        />

        <FormHelperText>{getErrorMessage('returnAddressState')}</FormHelperText>
      </FormControl>
    </>
  );
}