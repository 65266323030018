import { GridColDef } from '@mui/x-data-grid-pro';
import { CampaignType, NeighborhoodLetterStatus } from 'model/letterBatch';

export const columns: GridColDef[] = [
  {
    field: 'territoryId',
    headerName: 'Territory',
    width: 200,
    renderCell: (params: any) => {
      return (<div>{params.row.territoryName}</div>);
    },
  },
  {
    field: 'neighborhoodId',
    headerName: 'Neighborhood',
    width: 300,
    renderCell: (params: any) => {
      return <div>{params.row.neighborhoodName}</div>;
    },
  },
  {
    field: 'campaignType',
    headerName: 'Campaign Type',
    width: 300,
    renderCell: (params: any) => {
      let option = CampaignType.list.find((item) => item.id === params.row.campaignType);
      return <div>{option?.name ?? 'unknown'}</div>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 300,
    renderCell: (params: any) => {
      let option = NeighborhoodLetterStatus.list.find((item) => item.id === params.row.neighborhoodLetterStatus);
      return <div>{option?.name ?? 'unknown'}</div>;
    },
  },
];